import React from 'react'
import loadable from '@loadable/component';
import { graphql } from 'gatsby';
import { Col, Container, Row } from 'react-bootstrap';

import Layout from '../components/layout'
import BreadcrumbModule from '../components/BreadcrumbModule/BreadcrumbModule'
import NewsLetterModule from '../components/NewsLetterModule/NewsLetterModule'
import StickyButtons from '../components/StickyButtons/StickyButtons'
import Seo from "../components/seo"
import GoogleReviews from '../components/GoogleReviews/GoogleReviews';

const StaticIntro = loadable(() => import("../components/StaticIntro/StaticIntro"));
const ReviewSlider = loadable(() => import("../components/ReviewSlider/ReviewSlider"));
const ReviewsBadge = loadable(() => import("../components/ReviewSlider/ReviewBadge"));
const AllAgents = loadable(() => import("../components/AllAgents/AllAgents"));

const ReviewsTemplate = ({ data }) => {
    const PageData = data?.strapiPage
    const elfData = data?.site.siteMetadata?.elfSight
    const allAgentData = data?.site.siteMetadata?.allAgent
    return (
        <Layout footerCta="footercta" popularSearch={PageData?.select_popular_search?.title}>
            <div className="layout-padding-top">
                <BreadcrumbModule
                    parentlabel={PageData.choose_menu[0]?.strapi_parent?.strapi_parent?.title}
                    parentlink={PageData.choose_menu[0]?.strapi_parent?.strapi_parent?.slug}
                    subparentlabel={PageData.choose_menu[0]?.strapi_parent?.title}
                    subparentlink={PageData.choose_menu[0]?.strapi_parent?.slug}
                    title={PageData.title}
                />
                {PageData?.Add_Page_Modules?.length > 0 && PageData.Add_Page_Modules?.map((module, index) => {
                    return (
                        <>
                            {module.strapi_component === "page-modules.plain-content" &&
                                <StaticIntro
                                    {...module}
                                    title={PageData.title}
                                    showGoogleReview
                                />
                            }
                            {module.strapi_component === "page-modules.video-review" &&
                                <>
                                    <div className='review-landing-wrapper'>
                                        <ReviewSlider reviews={module.add} id={PageData.strapi_id} imagetransforms={PageData.imagetransforms}/>
                                    </div>
                                    {((elfData.review && elfData.review_page) || allAgentData.review) &&
                                        <div className='layout-grey-bg-theme reviews-wrapper'>
                                            {elfData.review && elfData.review_page &&
                                                <Container>
                                                    <Row>
                                                        <Col lg={8}>
                                                            <h1>Real reviews from real customers.</h1>
                                                            <div className="elf-review-page-module">
                                                                <ReviewsBadge code={elfData.review_page} />
                                                            </div>
                                                        </Col>
                                                        {/* <Col lg={4}>
                                                        <GoogleReviews tag="dark" />
                                                    </Col> */}
                                                    </Row>
                                                </Container>
                                            }
                                            {allAgentData.review &&
                                                <Container>
                                                    <Row>
                                                        <Col lg={8}>
                                                            <h1>Real reviews from real customers.</h1>
                                                            <div className="elf-review-page-module">
                                                                <AllAgents code={allAgentData.id} />
                                                            </div>
                                                        </Col>
                                                        <Col lg={4}>
                                                            <GoogleReviews tag="dark" />
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            }
                                        </div>
                                    }
                                </>
                            }
                        </>
                    )
                })}
                <NewsLetterModule />
            </div>
            <StickyButtons tag="default" />
        </Layout >
    )
}

export const Head = ({ data }) => {
    const PageData = data?.strapiPage
    return (
        <Seo title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} />
    )
}

export default ReviewsTemplate


export const query = graphql`
query ($page_id: String) {
    strapiPage(id: {eq: $page_id}) {
        ...PageFragment
        Add_Page_Modules {
            ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
              ...GlobalModuleFragment
            }
            ... on STRAPI__COMPONENT_PAGE_MODULES_VIDEO_REVIEW {
              ...VideoReviewFragment
            }
            ... on STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT {
              ...PlainContentFragment
            }
        }
    }

    site {
        siteMetadata {
            elfSight {
              review
              review_badge
              review_carousel
              review_page
            }
            allAgent {
              review
              id
            }
        }
      }
  }
`