import React from "react";
import loadable from '@loadable/component';
import { graphql, useStaticQuery } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import NewLetterFormFields from "../../forms_config/newsletter_horizondal_form.json";

import ScrollAnimation from 'react-animate-on-scroll';
import './assets/styles/_index.scss';
const DefaultForm = loadable(() => import("../forms/default-form-layout"))

const NewsLetterModule = (props) => {

    const data = useStaticQuery(graphql`
        query {
            strapiSiteConfig {
                newsletter_module {
                    title
                    content {
                        data {
                            content
                        }
                    }
                }
            }
        }
    `);

    const newsletterData = data?.strapiSiteConfig?.newsletter_module;

    return (
        <section className="news-letter-module-wrapper">
            <Container>
                <Row>
                    <Col>
                        <ScrollAnimation animateIn="animate__fadeIn" delay={100} animateOnce offset={50}>
                            <div className="primary-text-line">{props.short_title || newsletterData?.title}</div>
                        </ScrollAnimation>
                    </Col>
                </Row>
                <Row>
                    <Col xl={6}>
                        <ScrollAnimation animateIn="animate__fadeIn" delay={100} animateOnce offset={50}>
                            {props?.title ?
                                <div className="news-letter-module-title"><h3>{props.title}</h3></div>
                                :
                                <div className="news-letter-module-title" dangerouslySetInnerHTML={{ __html: newsletterData?.content?.data?.content }} />
                            }
                        </ScrollAnimation>
                    </Col>
                    <Col xl={6}>
                        <ScrollAnimation animateIn="animate__fadeIn" delay={100} animateOnce offset={50}>
                        <DefaultForm fields={NewLetterFormFields} classname="subscribe-form-wrapper" showRequiredOnly />
                        </ScrollAnimation>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default NewsLetterModule