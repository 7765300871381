import React, { useEffect, useState } from 'react'
import "./assets/styles/_index.scss"
import { Link, graphql, navigate, useStaticQuery } from 'gatsby';
import { PageLinks } from '../../common/site/page-static-links';

const StickyButtons = (props) => {

    const { tag, phone, teamData, careerData, company_phone, crm_id, display_address, propImg } = props
    const data = useStaticQuery(graphql`
        query {
            strapiSiteConfig {
                add_contact_details {
                  phone
                }
            }
        }
    `);

    const contactDetails = data?.strapiSiteConfig?.add_contact_details;

    // Sticky scroll
    const [scroll, setScroll] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 450)
        })
        if (scroll) {
            document.body.classList.add('chat-with-button-scroll');
        } else {
            document.body.classList.remove('chat-with-button-scroll');
        }
    }, [scroll])
    // Sticky scroll


    const JobSection = () => {
        const element = document.querySelector(".career-landing-section");
        if (element) {
            window.scrollTo({
                behavior: 'smooth',
                top:
                    element.getBoundingClientRect().top -
                    document.body.getBoundingClientRect().top -
                    450,
            })
        }
    }

    const number = phone ? phone : contactDetails?.phone

    const getWhatsAppURL = (number) => {
        if (!number) return null
        // remove everything except numbers
        const formatedNumber = number?.replace(/\D/g, "")

        return `https://api.whatsapp.com/send?phone=${formatedNumber}`
    }

    const whatsappNumber = getWhatsAppURL(number)

    const url = typeof window !== 'undefined' ? window.location.href : ''

    return (
        <div className='d-md-none'>
            {scroll &&
                <section className={`sticky-button-wrapper ${tag}`}>
                    {tag === "default" &&
                        <>
                            <Link to={`/${PageLinks.valuation}/`} className='button button-primary'>Book a valuation</Link>
                            <Link to={`/${PageLinks.enquiry}/`} className='button button-primary-outline'>Contact</Link>
                        </>
                    }
                    {tag === "property-details" &&
                        <>
                            <a onClick={() => navigate('/book-a-viewing', { state: { id: crm_id, address: display_address, pageurl: url, propImage: propImg && propImg.length > 0 ? propImg[0] : '' } })} className='button button-primary'>Enquire now</a>
                            {company_phone && <a href={`tel: ${company_phone}`} className='button button-primary-outline'><i className='icon icon-phone-sticky'></i>Call</a>}
                        </>
                    }
                    {tag === "landing-page" &&
                        <>
                            <Link to={`/${PageLinks.valuation}/`} className='button button-primary'>Book a valuation</Link>
                            {number && <a href={`tel: ${number}`} className='button button-primary-outline'><i className='icon icon-phone-sticky'></i>Call</a>}
                        </>
                    }
                    {tag === "team-details" &&
                        <>
                            <a href="javascript:;" onClick={() => navigate('/about/our-team/contact-team/', { state: { name: teamData?.name, email: teamData?.email } })} className="d-flex align-items-center button button-primary"><i className="icon icon-team-mail"></i> Enquire</a>
                            {number && <a href={`tel: ${number}`} className='button button-primary-outline'><i className='icon icon-phone-sticky'></i>Call</a>}
                            {whatsappNumber && <a href={whatsappNumber} target='_blank' className='button button-primary-outline'><i className='icon icon-whatsapp-sticky'></i>Whatsapp</a>}
                        </>
                    }
                    {tag === "careers-page" &&
                        <>
                            <a href="javascript:;" onClick={() => JobSection()} className='button button-primary'>Current openings</a>
                            <a href="javascript:;" onClick={() => navigate('/about/careers/apply/')} className="button button-primary-outline">Send us your cv</a>

                        </>
                    }
                    {tag === "careers-detail-page" &&
                        <>
                            <a onClick={() => navigate('/about/careers/apply/', { state: { id: careerData?.crm_id, title: careerData?.title, address: careerData?.location } })} className='button button-primary'>Apply for this Job</a>
                            <Link to={`/${PageLinks.contact}/`} className='button button-primary-outline'>contact</Link>
                        </>
                    }
                </section>
            }
        </div>
    )
}

export default StickyButtons