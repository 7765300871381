import React from "react";
import ReviewsImg from "../../images/google_reviews.svg";
import ReviewsImgBlack from "../../images/google_reviews_black.svg";
import './assets/styles/_index.scss';
import { graphql, useStaticQuery } from "gatsby";
import ReviewsBadge from "../ReviewSlider/ReviewBadge";

const GoogleReviews = (props) => {
    const { site } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    elfSight {
                        review
                        review_badge
                        review_carousel
                        review_page
                    }
                }
            }
        }
    `)

    const elfData = site.siteMetadata?.elfSight
    return (
        <>
            <div className={`google-reviews-wrapper d-flex align-items-center ${props.tag}`}>
                {
                    props.tag === "dark" ?
                        <img src={ReviewsImgBlack} alt="Google Review Logo - Dark" className="img-fluid" width={"auto"} height={"auto"} />
                        :
                        <img src={ReviewsImg} alt="Google Review Logo" className="img-fluid" width={"auto"} height={"auto"} />
                }
                <div className="reviews-text"><span>4.9/5</span> from 148 Reviews</div>
            </div>
            {elfData.review &&
                <div className={`google-reviews-wrapper d-flex align-items-center ${props.tag}`}>
                    <ReviewsBadge code={elfData.review_badge} />
                </div>
            }
        </>
    )
}

export default GoogleReviews